body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  :root { font-family: 'Inter var', sans-serif; }
}

.graphContainer {
    height: 100%;
    width: 100%;
}

/* Vertical graph */

.outPort {
  position: absolute;
  top: 100%;
  left: 50%;
}

.inPort {
  position: absolute;
  top: 0%;
  left: 50%;
}

.inPortDestination {
  position: absolute;
  top: 0%;
  left: 0%;
}

.inPortAfferentTDestination {
  position: absolute;
  top: 100%;
  left: 100%;
}

.tooltipWarning {
  color: #DC6803 !important;
}
